/* eslint-disable prefer-destructuring */
/* eslint-disable no-bitwise */
/* eslint-disable no-new */
import "./view.css";
import { Point } from "ol/geom.js";
import { Feature } from "ol/index.js";
import { Vector as VectorSource } from "ol/source.js";
import { Vector as VectorLayer } from "ol/layer.js";
import { useGeographic } from "ol/proj.js";
import "ol/ol.css";
import Select from "ol/interaction/Select.js";
import { Fill, Stroke, Style, Circle, RegularShape } from "ol/style.js";

import { altKeyOnly, click, pointerMove } from "ol/events/condition.js";
import { easeOut } from "ol/easing.js";
import { getVectorContext } from "ol/render.js";
import { unByKey } from "ol/Observable.js";

// eslint-disable-next-line react-hooks/rules-of-hooks
useGeographic();

const duration = 500;
const flash = ({ features, map, tileLayer, animate: a }) => {
  const start = Date.now();
  const flashGeom = features[0].getGeometry().clone();
  const listenerKey = tileLayer.on("postrender", animate);

  function animate(event) {
    const { frameState } = event;
    const elapsed = frameState.time - start;
    if (elapsed >= duration) {
      unByKey(listenerKey);

      if (a === "forever") flash({ features, map, tileLayer, animate: a });
      return;
    }

    const vectorContext = getVectorContext(event);
    const elapsedRatio = elapsed / duration;
    // radius will be 5 at start and 30 at end.
    const radius = easeOut(elapsedRatio) * 15 + 5;
    const opacity = easeOut(1 - elapsedRatio);

    const style = new Style({
      image: new Circle({
        radius: radius,
        stroke: new Stroke({
          color: `rgba(255, 0, 0, ${opacity})`,
          width: 0.25 + opacity,
        }),
      }),
    });

    vectorContext.setStyle(style);
    vectorContext.drawGeometry(flashGeom);
    // tell OpenLayers to continue postrender animation
    map.render();
  }
};

const style = {
  dot: ({ color, strokeColor }) =>
    new Style({
      image: new Circle({
        radius: 10,
        fill: new Fill({ color }),
        stroke: new Stroke({
          color: strokeColor,
          width: 4,
        }),
      }),
    }),
  square: ({ color, strokeColor }) =>
    new Style({
      image: new RegularShape({
        fill: new Fill({ color }),
        stroke: new Stroke({
          color: strokeColor,
          width: 4,
        }),
        points: 4,
        radius: 13,
        angle: Math.PI / 4,
      }),
    }),
  diamond: ({ color, strokeColor }) =>
    new Style({
      image: new RegularShape({
        fill: new Fill({ color }),
        stroke: new Stroke({
          color: strokeColor,
          width: 4,
        }),
        points: 4,
        radius: 13,
        angle: Math.PI / 4,
        rotation: Math.PI / 4,
      }),
    }),
  rectangle: ({ color, strokeColor }) =>
    new Style({
      image: new RegularShape({
        fill: new Fill({ color }),
        stroke: new Stroke({
          color: strokeColor,
          width: 4,
        }),
        radius: 10 / Math.SQRT2,
        radius2: 10,
        points: 4,
        angle: 0,
        scale: [1, 0.5],
      }),
    }),
  triangle: ({ color, strokeColor }) =>
    new Style({
      image: new RegularShape({
        fill: new Fill({ color }),
        stroke: new Stroke({
          color: strokeColor,
          width: 4,
        }),
        points: 3,
        radius: 14,
        rotation: Math.PI / 4,
        angle: 0,
      }),
    }),
  star: ({ color, strokeColor }) =>
    new Style({
      image: new RegularShape({
        fill: new Fill({ color }),
        stroke: new Stroke({
          color: strokeColor,
          width: 4,
        }),
        points: 5,
        radius: 13,
        radius2: 6,
        angle: 0,
      }),
    }),
  cross: ({ color, strokeColor }) =>
    new Style({
      image: new RegularShape({
        fill: new Fill({ color }),
        stroke: new Stroke({
          color: color,
          width: 6,
        }),
        points: 4,
        radius: 10,
        radius2: 0,
        angle: 0,
      }),
    }),
  x: ({ color, strokeColor }) =>
    new Style({
      image: new RegularShape({
        fill: new Fill({ color }),
        stroke: new Stroke({
          color: strokeColor,
          width: 4,
        }),
        points: 4,
        radius: 10,
        radius2: 0,
        angle: Math.PI / 4,
      }),
    }),
};

const types = {
  default: {
    color: "#ff4040",
    strokeColor: "rgba(255, 255, 255, 0.7)",
    style: "dot",
  },
  selected: {
    color: "yellow",
    strokeColor: "rgba(255, 255, 255, 0.7)",
    animate: "once",
    style: "dot",
  },
  pulse: {
    color: "yellow",
    strokeColor: "rgba(10, 10, 10, 0.3)",
    animate: "forever",
    style: "dot",
  },
};

function isLightColor(color = "#ffffff") {
  // gist: https://gist.github.com/krabs-github/ec56e4f1c12cddf86ae9c551aa9d9e04
  // eslint-disable-next-line one-var, one-var-declaration-per-line
  let r, g, b;

  // Check the format of the color, HEX or RGB?
  if (color.match(/^rgb/)) {
    // If HEX --> store the red, green, blue values in separate variables
    color = color.match(
      /^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/
    );

    r = color[1];
    g = color[2];
    b = color[3];
  } else {
    // If RGB --> Convert it to HEX: http://gist.github.com/983661
    color = +`0x${color.slice(1).replace(color.length < 5 && /./g, "$&$&")}`;

    r = color >> 16;
    g = (color >> 8) & 255;
    b = color & 255;
  }

  // HSP equation from http://alienryderflex.com/hsp.html
  const hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));

  // Using the HSP value, determine whether the color is light or dark
  return hsp > 180;
}

const useMapDot = ({ type, points, map, tileLayer, onClick }) => {
  const settings = types[type];

  if (!(points instanceof Array)) points = [points];

  const features = points.map((point) => {
    const features = [new Feature(new Point(point.coordinate))];

    map.addLayer(
      new VectorLayer({
        source: new VectorSource({ features }),
        style: style[point.shape || settings.style]({
          color: point.color,
          strokeColor: isLightColor(point.color)
            ? "rgba(10, 10, 10, 0.3)"
            : "rgba(255, 255, 255, 0.7)",
        }),
      })
    );
    return features[0];
  });

  if (settings.animate) flash({ ...settings, features, map, tileLayer });
  if (!onClick) return features;

  const selectClick = new Select({
    condition: click,
    // style: style.dot(types[onClickType]),
  });

  map.addInteraction(selectClick);

  selectClick.on("select", (e) => {
    const pos = features.indexOf(e.selected[0]);
    if (pos === -1) return;

    onClick(pos);
    console.log("sele", pos, settings.animate);
    // if (settings.animate)
    flash({ ...settings, features: e.selected, map, tileLayer });
  });
  return features;
};

export default useMapDot;
// https://openlayers.org/en/latest/examples/regularshape.html
