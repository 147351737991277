import calculateSize from "./calculateSize";

export default function (app) {
  const { waitInLine, init } = app.waitInLine;
  init("init", { delay: 10 });
  init("upload", { delay: 200 });

  function paste(e) {
    const { items } = e.clipboardData || e.originalEvent.clipboardData;
    if (e.clipboardData.types[0] === "text/plain") {
      // var text = e.clipboardData.getData('text/plain');
      // if (/^http:\/\//.test(text))
      //  addURL2FileManager(text);
    } else if (e.clipboardData.types[0] === "Files") {
      const URL = window.URL.createObjectURL(items[0].getAsFile());
      uploadFile({
        URL: URL,
        blob: items[0].getAsFile(),
        type: "image/png",
      });
      e.stopPropagation();
      e.preventDefault();
    }
  }

  const entry2File = (entry) => new Promise((resolve) => entry.file(resolve));
  const readDir = (handle) =>
    new Promise((resolve) =>
      handle.createReader().readEntries((entries) => {
        const out = [];
        for (const entry of entries) out.push(entry);
        resolve(out);
      })
    );

  const fileFinder = (handle) =>
    handle.isDirectory ? readDir(handle) : handle;

  async function drop(e) {
    e.preventDefault();

    let type = e.dataTransfer.types[0];
    for (let i = 0; i < e.dataTransfer.types.length; i++)
      type = e.dataTransfer.types[i];

    if (type === "text/plain") {
      console.log(e.dataTransfer.getData("text/plain"));
    } else if (type === "text/html") {
      /*        var divTag = document.createElement("div");
        $(divTag).html(e.dataTransfer.getData('text/html'));
        var img = $('img',$(divTag));
        if (!exist(img)) return;
        if (exist(img.attr('data-id')) && img.attr('data-site')==location.href)
          id = img.attr('data-id');
        else
          addURL2FileManager(img.attr('src')); */
    } else if (type === "text/uri-list") {
      /* addURL2FileManager(e.dataTransfer.getData('text/uri-list')); */
    } else if (type === "Files") {
      const files = [];
      const entries = [...e.dataTransfer.items].map((item) =>
        item.webkitGetAsEntry()
      );

      for (let i = 0; i < entries.length; i++) {
        const entry = entries[i];
        if (!entry) continue;

        if (entry.isDirectory) {
          const entries = await fileFinder(entry);

          console.log("Folder count", entries.length);
          const mediaId = app.uuid();
          // eslint-disable-next-line no-loop-func
          entries.forEach(async (file) =>
            app.event("fileUpload", {
              name: entry.name,
              folderName: entry.name,
              file: await entry2File(file),
              id: app.uuid(),
              mediaId,
            })
          );
        } else files.push(entry);
      }

      console.log("Files count", files.length, files[0]);
      const mediaId = app.uuid();
      // eslint-disable-next-line no-loop-func
      files.forEach(async (file) =>
        app.event("fileUpload", {
          name: file.name,
          file: await entry2File(file),
          mediaId,
          id: app.uuid(),
        })
      );
    }
  }

  const uploadFile = (query) =>
    new Promise((resolve) => {
      const { id, mediaId, params, contentType, name, ext, type, data, URL } =
        query;

      const xhr = new XMLHttpRequest();
      const { upload } = xhr;
      let fileData = { id, mediaId, folderName: query.folderName };

      const progress = (ev) => {
        if (!ev.lengthComputable) return;

        const progress = parseInt((ev.loaded / ev.total) * 100, 10);
        app.event("fileUploadProgress", { ...fileData, progress });
      };
      upload.addEventListener("progress", progress, false);

      const load = () => {
        console.log("Upload complete", id, name);

        if (URL)
          app.event("fileUploadProgress", { ...fileData, status: "done" });
        resolve();
      };
      upload.addEventListener("load", load, false);

      const error = (error) => {
        app.event("fileUploadProgress", {
          ...fileData,
          status: "error",
          error,
        });
        console.log(error);
      };
      upload.addEventListener("error", error, false);

      const basseSend = {
        id,
        params,
        contentType,
        type: ["image", "audio", "video"].includes(type) ? type : "attachment",
      };

      if (URL)
        app.connection
          .emit({ data: { name, type, ext, URL, ...basseSend } })
          .then(() => {
            app.event("fileUploadProgress", { ...fileData, status: "done" });
          });
      else {
        app.connection
          .emit({ type: "fileUpload", data: { name, type, ext, ...basseSend } })
          .then(() => {
            xhr.onreadystatechange = function () {
              if (this.readyState !== 4) return;

              app.event("fileUploadProgress", {
                ...fileData,
                ...JSON.parse(this.responseText || "{}"),
                status: this.status === 200 ? "done" : "error",
              });
            };

            fileData = { ...fileData, name, ext, type };
            app.event("fileUploadProgress", { ...fileData, status: "start" });
            console.log("Start upload", id, name);
            xhr.open("POST", "/file");
            xhr.setRequestHeader("Cache-Control", "no-cache");
            xhr.setRequestHeader("X-Requested-With", "XMLHttpRequest");
            xhr.setRequestHeader("X-File-Name", `file${id}`);
            xhr.send(data);
          });
      }
    });

  window.addEventListener("paste", paste, false);
  window.addEventListener("dragover", stopOver, false);
  document.body.addEventListener("dragover", stopOver, false);

  function stopOver(e) {
    e = e || e.event;
    e.preventDefault();
  }

  window.addEventListener("drop", drop, false);
  calculateSize(app);

  const getTypeAndExt = ({ name, type }) => {
    if (/\.jpe?g$/im.test(name)) return ["image", "jpeg"];
    if (/\.png$/im.test(name)) return ["image", "png"];
    if (/\.mp3$/im.test(name)) return ["audio", "mp3"];
    if (/\.m4a$/im.test(name)) return ["audio", "m4a"];
    if (/\.wav$/im.test(name)) return ["audio", "wav"];
    if (/\.mp4$/im.test(name)) return ["video", "mp4"];
    if (/\.mpg$/im.test(name)) return ["video", "mpg"];
    if (/\.avi$/im.test(name)) return ["video", "avi"];
    if (/\.mov$/im.test(name)) return ["video", "mov"];
    if (/\.svg$/im.test(name)) return ["image", "svg"];

    if (type) return type.split("/");
    return ["application", name.substring(name.lastIndexOf(".") + 1)];
  };

  app.on("fileUpload", 1000, async (query) => {
    if (!query.file) return;

    const [type, ext] = getTypeAndExt(query.file);

    return new Promise((resolve) => {
      const reader = new FileReader();

      reader.onload = (e) =>
        resolve({
          ...query,
          data: e.target.result,
          file: undefined,
          name: query.file.name,
          type,
          ext,
        });
      reader.onerror = () => resolve(undefined);

      reader.readAsArrayBuffer(query.file);
    });
  });

  const getFullPath = (query) => {
    const { type, ext, id, params = [] } = query;
    const add = params.length ? `${params.join("/")}/` : "";

    const myType = ["image", "audio", "video"].includes(type)
      ? type
      : "attachment";

    return `/${myType}/${add}${id}.${ext}`;
  };

  app.on("fileUpload", 900, (query) => {
    if (query.data) query.size = query.data.byteLength;
  });
  // Secure firefox check bug check unsaved
  app.on("fileUpload", 900, async (query) => {
    const nystemCache = await caches.open("nystem");
    const { data, type, ext } = query;

    await nystemCache.put(
      getFullPath(query),
      new Response(data, {
        status: 200,
        statusText: "Uploader",
        headers: new Headers({
          "content-type": `${type}/${ext}`,
          date: new Date(),
          etag: "upload",
        }),
      })
    );
  });

  app.on("fileUpload", -900, async (query) => {
    const nystemCache = await caches.open("nystem");
    const fullPath = getFullPath(query);

    const req = await nystemCache.match(fullPath);
    req.headers.set("etag", "uploaded");
    await nystemCache.put(fullPath, req);
  });

  app.on("fileUpload", 900, async (query) => {
    if (query.type !== "image" || query.width) return;

    const { data, type, ext } = query;

    const blob = new Blob([new Uint8Array(data)], {
      type: `${type}/${ext}`,
    });

    return new Promise((resolve) => {
      const image = new window.Image();
      image.src = URL.createObjectURL(blob);
      image.onload = () =>
        resolve({ ...query, width: image.width, height: image.height });
      image.onerror = () => resolve(undefined);
    });
  });

  app.on("fileUpload", async (query) => {
    if (!query.id) return;
    const { type, width, height, ext, id, mediaId, name, pos, size } = query;

    await waitInLine("init", async () => {
      await app.event("fileUploadProgress", {
        id,
        mediaId,
        status: "init",
        type: ["image", "audio", "video"].includes(type) ? type : "attachment",
        width,
        height,
        ext,
        name,
        folderName: query.folderName,
        pos,
        size,
      });
    });

    return waitInLine("upload", () => uploadFile(query));
  });

  if (!window.caches) return;

  let keys = [];

  const loadKeys = async () => {
    try {
      const nystemCache = await window.caches.open("nystem");
      const items = await nystemCache.keys();
      keys = items.map((item) => item.url);

      // const req = await nystemCache.match(keys[5]);
      // console.log("req", req.headers.get("etag"));
    } catch (e) {
      console.log(e);
    }
  };
  loadKeys();

  function upload(item, value) {
    const filetype =
      item.filetype instanceof Array ? item.filetype[0] : item.filetype;

    if (value.id && !keys.find((key) => key.includes(value.id)))
      fetch(
        `/${filetype}/200x${parseInt((200 * value.height) / value.width, 10)}/${
          value.id
        }.${value.ext}`
      );
  }

  function addToCache(item, value) {
    if (!value) return;

    if (item.type === "media") {
      if (value instanceof Array) value.forEach((value) => upload(item, value));
      else upload(item, value);
    }

    if (item.type === "group")
      item.item.forEach((item) => addToCache(item, value[item.id]));

    if (item.type === "multigroup") {
      const val = value[item.id] || [];
      val.forEach((itemval, index) =>
        item.item.forEach((item) => addToCache(item, val[index][item.id]))
      );
    }
  }

  app.on("addToCache", (data) => {
    const items = app.contentType[data.contentType].item;

    if (data.value)
      items.forEach((item) => {
        addToCache(item, data.value[item.id]);
      });
  });
}
